import React from "react"
import { Button, Col, List, Popover, Row, Typography } from "antd"
import { EnvironmentTwoTone } from "@ant-design/icons"
import { graphql } from "gatsby"
import GoogleMapReact from "google-map-react"
import Shell from "../../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../../components/SharpHero/SharpHero"
import BookingBar from "../../components/BookingBar/BookingBar"
import PackagesWidget from "../../components/PackagesWidget/PackagesWidget"
import SocialSharing from "../../components/SocialSharing/SocialSharing"

const description = `Check out all that The Residences at Biltmore all suite hotel and downtown Asheville have to offer. Guests will enjoy amazing dining, activities, attractions, shopping, and much more!`

const mapOptions = {
  panControl: true,
  mapTypeControl: true,
  streetViewControl: true,
  scrollwheel: true,
  disableDefaultUI: false,
}

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    marginBottom: 32,
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: 1360,
  },
}

const colProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  style: {
    paddingTop: 32,
    paddingBottom: 32,
  },
}

const categories = {
  rab: { color: `teal`, label: `Lodging` },
  food: { color: `red`, label: `Food & Drink` },
  park: { color: `green`, label: `Parks & Nature` },
  hiking: { color: `rebeccapurple`, label: `Hiking & Adventure` },
  attraction: { color: `orange`, label: `Local Attractions` },
}

const Marker = ({ active, cat, lat, lng, name }) => (
  <Popover content={categories[cat].label} title={name}>
    <EnvironmentTwoTone
      lat={lat}
      lng={lng}
      twoToneColor={active ? `blueviolet` : categories[cat].color}
      style={{
        fontSize: active ? 32 : 24,
        transform: `translate(-12px, -6px)`,
        zIndex: active ? 99999 : 1,
      }}
    />
  </Popover>
)

export default ({ data: { hero, hotel }, location }) => {
  const [map, setMap] = React.useState({
    lat: hotel.location.lat,
    lng: hotel.location.lon,
    zoom: 9,
  })

  const POIs = [
    {
      name: `The Residences at Biltmore`,
      cat: `rab`,
      lat: hotel.location.lat,
      lng: hotel.location.lon,
    },
    {
      name: `Curaté`,
      cat: `food`,
      lat: `35.594423862068844`,
      lng: `-82.551698730451`,
    },
    {
      name: `12 Bones Steakhouse`,
      cat: `food`,
      lat: `35.579710308745376`,
      lng: `-82.56581095664457`,
    },
    {
      name: `Posana Restaurant`,
      cat: `food`,
      lat: `35.594756940115`,
      lng: `-82.55179105928745`,
    },
    {
      name: `The Admiral`,
      cat: `food`,
      lat: `35.57799383528063`,
      lng: `-82.5798304304513`,
    },
    {
      name: `The Corner Kitchen`,
      cat: `food`,
      lat: `35.56650256238348`,
      lng: `-82.54119243045143`,
    },
    {
      name: `The Market Place`,
      cat: `food`,
      lat: `35.59486825774115`,
      lng: `-82.55527900161444`,
    },
    {
      name: `Tupelo Honey Cafe`,
      cat: `food`,
      lat: `35.59496177708063`,
      lng: `-82.55489306130693`,
    },
    {
      name: `Vue 1913`,
      cat: `food`,
      lat: `35.62073906432676`,
      lng: `-82.54247660161406`,
    },
    {
      name: `White Duck Taco Shop (Downtown)`,
      cat: `food`,
      lat: `35.595974828706154`,
      lng: `-82.55117273790138`,
    },
    {
      name: `White Duck Taco Shop (Riverside)`,
      cat: `food`,
      lat: `35.59618420595236`,
      lng: `-82.5721154267444`,
    },
    {
      name: `Asheville Art Museum`,
      cat: `attraction`,
      lat: `35.59495927847841`,
      lng: `-82.55125051510495`,
    },
    {
      name: `Biltmore Estate`,
      cat: `attraction`,
      lat: `35.542207741710975`,
      lng: `-82.55214873790139`,
    },
    {
      name: `Estes-Winn Antique Car Museum`,
      cat: `attraction`,
      lat: `35.621757689489215`,
      lng: `-82.54497586089144`,
    },
    {
      name: `River Arts District`,
      cat: `attraction`,
      lat: `35.584930013362914`,
      lng: `-82.5661638881241`,
    },
    {
      name: `The Gorge - A Zipline Canopy Tour`,
      cat: `hiking`,
      lat: `35.25317843492632`,
      lng: `-82.32433637278373`,
    },
    {
      name: `Green River Adventures`,
      cat: `hiking`,
      lat: `35.23561731255351`,
      lng: `-82.346529672784`,
    },
    {
      name: `Sliding Rock Falls`,
      cat: `hiking`,
      lat: `35.31223364802131`,
      lng: `-82.7873202576717`,
    },
    {
      name: `Craggy Pinnacle`,
      cat: `hiking`,
      lat: `35.704248813790535`,
      lng: `-82.37785871152745`,
    },
    {
      name: `Pink Beds Trail Loop`,
      cat: `hiking`,
      lat: `35.35353029039149`,
      lng: `-82.77870213045503`,
    },
    {
      name: `Blue Ridge Parkway`,
      cat: `park`,
      lat: `35.520372788264986`,
      lng: `-82.52822272391462`,
    },
    {
      name: `Botanical Gardens at Asheville`,
      cat: `park`,
      lat: `35.61412356874912`,
      lng: `-82.56610512997733`,
    },
    {
      name: `Black Balsam Knob`,
      cat: `park`,
      lat: `35.328723686188546`,
      lng: `-82.87416898093734`,
    },
    {
      name: `DuPont State Forest`,
      cat: `park`,
      lat: `35.19020765046336`,
      lng: `-82.60342051511182`,
    },
    {
      name: `Looking Glass Falls`,
      cat: `park`,
      lat: `35.29626990910003`,
      lng: `-82.76881211510998`,
    },
    {
      name: `Shining Rock Wilderness`,
      cat: `park`,
      lat: `35.35529998530875`,
      lng: `-82.8440838746375`,
    },
    {
      name: `The North Carolina Arboretum`,
      cat: `park`,
      lat: `35.498428752938025`,
      lng: `-82.60983650131006`,
    },
    {
      name: `WNC Nature Center`,
      cat: `park`,
      lat: `35.57959166157846`,
      lng: `-82.49427168711993`,
    },
  ]

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Asheville Area Map - The Residences at Biltmore"
        url={location.href}
      />
      <Hero contentfulAsset={hero} height={`40vh`} />
      <BookingBar />
      <Row {...rowProps}>
        <Col {...colProps}>
          <Typography.Title>Area Map</Typography.Title>
          <Typography.Paragraph>
            Western North Carolina is home to the beautiful Smoky Mountains. You
            can relax and reconnect at The Residences at Biltmore all-suite
            hotel featuring a pool, fire pit, outdoor hot tub or on your very
            own private balcony. From family time, to one-on-one with that
            special someone, to a group of friends, the mountains have something
            for everyone. Enjoy hiking, zip-lining, and tours, or head out on
            the town for some of Asheville’s finest shopping and dining
            experiences close by.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <div
            style={{
              height: 500,
              marginBottom: 32,
              maxHeight: `75vh`,
              width: `100%`,
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
              center={[parseFloat(map.lat), parseFloat(map.lng)]}
              options={mapOptions}
              zoom={map.zoom}
            >
              {POIs.map((poi, index) => (
                <Marker key={index} active={map.active === poi.name} {...poi} />
              ))}
            </GoogleMapReact>
          </div>
          <div
            style={{
              background: `rgba(232, 230, 223, 1)`,
              height: 300,
              maxHeight: `50vh`,
              overflowY: `scroll`,
              scrollbarColor: `#666`,
              scrollbarWidth: `thin`,
              width: `100%`,
            }}
          >
            <List
              dataSource={POIs}
              itemLayout="vertical"
              size="small"
              renderItem={({ name, cat, lat, lng }, index) => (
                <List.Item key={index}>
                  <div
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                    }}
                  >
                    <Button
                      onClick={() =>
                        setMap({ active: name, lat, lng, zoom: 15 })
                      }
                      type="link"
                    >
                      <span style={{ fontWeight: `bold` }}>{name}</span>
                    </Button>
                    {categories[cat].label}
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <SocialSharing pageUrl={location.href} />
        </Col>
      </Row>
      <PackagesWidget />
    </Shell>
  )
}

export const query = graphql`
  {
    hero: contentfulAsset(title: { eq: "Explore Asheville - Skyline" }) {
      ...HeroFluid
    }
    hotel: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
      ...HotelContact
    }
  }
`
